import React, { Fragment } from "react";
import { ConfirmSignUp } from "aws-amplify-react";
import { Card, Input, Row, Col, Button, Section, Divider} from 'react-materialize'
import SonicConfirmationSignUp from './SonicConfirmationSignUp'
import LiveButton from './LiveButton';


export class CustomConfirmSignUp extends ConfirmSignUp {
  constructor(props) {
    super(props);
    this._validAuthStates = ["confirmSignUp"];
  }
  
  showComponent(theme) {
    const { authData={} } = this.props;
    const username = this.usernameFromAuthData();

    return <SonicConfirmationSignUp username={username} {...authData} changeState={this.changeState}/>

  }
}

/*

    return (
      <Card>
      <h4>Verify your email</h4>

      <h6>{username}</h6>
      <Input s={12}  
      id="code"
      key="code"
      name="code"
      onChange={this.handleInputChange} 
      label="Enter your code"/> 

      <LiveButton 
      title={'Submit'}
      onClick={() => super.confirm()}
      //success={success}
     // error={error}
     // loading={loading}
      />
        <Row m={12}>
        <Col m={6}>
        <h6 className="left-align"> <a style={{color: 'orange'}}  onClick={() => this.changeState('signIn')}>{'Skip'} </a> </h6>

        </Col>
        <Col m={6}>
        <h6 className="right-align"> <a style={{color: 'orange'}} onClick={() => super.resend()}>{'Resend Code'}</a> </h6>
       
        </Col>
        </Row>
   
       </Card>
    );

*/


