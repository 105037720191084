import React, { Component } from 'react';
import { withRouter} from "react-router-dom"
import Intro from './Intro'
import Admin from './Admin'
import Account from './Account'
import Manager from './Manager'
import MyVideos from './MyVideos'
import List from './List'
import './App.css';

class Home extends Component {
  constructor(props) {
    super(props);

    this.state = {
     /* admin: false,
      showManager: false,
      videoIndex: 0,
      unloadPlayer: false,
      videoID: null,
      volume: 1,
      count: 0,
      hiddenPaused: false,
      seconds: 0,
      duration: 0,
      type: null,
      agentVideos: [],
      videos: null,*/
    };
  }

  renderSelectedView() {
    console.log(this.props)
   // const { videos } = this.state
    const { selectedView, height } = this.props;

    //need to also check cache and make sure its not a api error we don't have videos
    if(selectedView === 'My Videos'){
   //   if(videos.length > 0){
        return <MyVideos {...this.state}  {...this.props} height={height} />
   //   }
      //return <MyVideos {...this.state} height={height} />
    }else if (selectedView === 'My Agents'){
      return <Manager {...this.props} {...this.state}   />
    }else if (selectedView === 'Manage Videos'){
      return <Admin {...this.props} {...this.state}  />
    }else if (selectedView === 'Account'){
      return <Account {...this.props} {...this.state} card={{}} />
    }else if (selectedView === 'Intro'){
      return <Intro {...this.props} {...this.state} height={height}/>
    }
    //return <MyVideos {...this.state}  {...this.props} height={height} />
    return <List items={this.props.videos['Test Frou']} height={height} />
  }
  render(){
    //const { height, backgroundColor } = this.props
    return <div>{this.renderSelectedView()} </div>
}

}
export default withRouter(Home);

//export default withAuthenticator(App, true);
