import React from "react";
import config from "./aws-exports";
import { CustomSignIn } from "./CustomSignIn";
import { withRouter} from "react-router-dom"

import App from "./App";
import { Authenticator } from "aws-amplify-react/dist/Auth";

class MainWithAuth extends React.Component {
  render() {
    return (
      <div>
        <Authenticator 
                hideDefault={true}
               // hide={[SignIn, SignUp, ForgotPassword, ConfirmSignUp, Greetings]} 
                amplifyConfig={config}>
          <CustomSignIn />
          <App />
        </Authenticator>
      </div>
    );
  }
}

export default withRouter(MainWithAuth);
//export default MainWithAuth;