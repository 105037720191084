import React, { Component, Fragment } from 'react';
import { Card, Container, Row, Col, Button, Section, Divider} from 'react-materialize'
import LiveButton from './LiveButton';
import './App.css';


/*

/free?email=jason@sonicwebdev.com

{
  "Item": {
    "admin": {
      "BOOL": false
    },
    "network": {
      "S": "Sonic"
    }
  }
}

*/


class SonicConfirmationSignUp extends Component {
  constructor(props) {
    super(props);
    this.state = {

    };
  }
  async componentDidMount(){
    console.log(this.props)
    const { username } = this.props;
    console.log(username)
    console.log(`${process.env.REACT_APP_APP_API_URL}/free?email=${username}`)
    const response = await fetch(`${process.env.REACT_APP_APP_API_URL}/free?email=${username}`)
   //check status and handle error
    console.log(response)
    const details = await response.json();
    console.log(details)
    const { Item } = details
    if(Item){
       const { network: company, admin: level } = Item
       let admin = false
       if(level){
        admin = level.BOOL
       }
  
       if(company){
        const network = company.S
        this.setState({username, network, admin})
       }else{
        this.setState({username, admin})  
       }

    }

    //handle error here too

    
  }
  render() {
      const { changeState } = this.props
      const { username, network, admin }  = this.state

    if(admin){
        return (
          <Card> <Container>
                <Row>
                    <h4>Welcome!</h4>   
                    <h6>{username}</h6>
                    <Divider />
                    <h5>Your account has been created</h5>
                    <h6>Get Started by Logging In</h6>
                    <h6><a style={{color: 'orange'}}  onClick={() => changeState('signIn')}>{'Go To Login'} </a> </h6>
                </Row>
            </Container> </Card>
        )
    }else{
       return (
       <Card><Container>
        <Row>
            <h4>Welcome Agent!</h4>   
            <h6>{username}</h6>
            <Divider />
           {network || network === 'none' ? <h5>{`You have been auto assigned to the ${network} network`}</h5> :
            <Fragment>
              <h5>{`Your email did not match to any current networks.`}</h5>
              <h6> {`Please have the network you want to join add you using your email address.`}</h6>
              <p>{`If you would like to purchase a paid plan to create your own network login and submit a support ticket to request an upgrade.`}</p>
            </Fragment>}
            <h6>Get Started by Logging In</h6>
            <h6><a style={{color: 'orange'}}  onClick={() => changeState('signIn')}>{'Go To Login'} </a> </h6>
        </Row>
    </Container> </Card>)

    }

  }
}

export default SonicConfirmationSignUp;

/*
fetch('http://example.com/movies.json')
  .then(function(response) {
    return response.json();
  })
  .then(function(myJson) {
    console.log(JSON.stringify(myJson));
  });

*/