import React, {Fragment} from "react";
import { SignIn, SignUp, ForgotPassword, Greetings, ConfirmSignUp } from "aws-amplify-react";
import config from "./aws-exports";
import { CustomSignIn } from "./CustomSignIn";
import { CustomSignUp } from "./CustomSignUp";
import { CustomConfirmSignUp } from "./CustomConfirmSignUp";
import { CustomForgotPassword } from "./CustomForgotPassword";
import AuthApp from "./AuthApp";
import { Authenticator } from "aws-amplify-react/dist/Auth";

class AppWithAuth extends React.Component {

  switchToSignUp(){
    this.signIn.switchToSignUp()
  }
  render() {
    const { newUser } = this.props
    console.log( this.props)

    return (
      <div>
        <Authenticator 
               // onStateChange={this.handleStateChange} 
                hide={[SignIn, SignUp, ForgotPassword, ConfirmSignUp, Greetings]} 
                amplifyConfig={config}
                authState={newUser ? 'signUp' : 'signIn'}
                >
          <CustomForgotPassword />
          <CustomConfirmSignUp />
            <CustomSignUp {...this.props} />
            <CustomSignIn ref={(ref) => {this.signIn = ref}} /> 
          <AuthApp />
        </Authenticator>
      </div>
    );
  }
}

export default AppWithAuth;