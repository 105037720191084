import React, { Component } from 'react';
import {Row, Col, Section, Card, Button, Divider} from 'react-materialize'
import { withRouter} from "react-router-dom"
import qs from 'qs'
import AppWithAuth from './AppWithAuth'
import Footer from './Footer'
import logo from './vm_icon.png';

class FrontPage extends Component {
  state = {
    height: 0,
  };
  componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener('resize', this.updateWindowDimensions);
    const settings = qs.parse(window.location.search.substring(1));
    const hostname = window.location.hostname
    console.log(hostname)
    if(hostname !== 'videomanager.com' && hostname !== 'localhost' && hostname !== 'd33604le1y94ai.cloudfront.net' && hostname !== 'test.videomanager.com'){
      this.props.history.push('/users')
    }
    console.log(settings)
    const { planName, ...other } = settings 

    this.setState({...other}, () => {
      //this.selectedPlan(planName)
    })
  }
  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions);
  }
  updateWindowDimensions = () => {
    // document.removeEventListener('keydown', this.onKeydown);

    const windowHeight = window.innerHeight;

    if(this.footer){
        console.log(this.footer.divElement.offsetTop)
        const footerHeight = this.footer.divElement.clientHeight
     
        const height = windowHeight - (footerHeight ? footerHeight : 0)
        //const height = footerTopOffset ? footerTopOffset : windowHeight
        console.log(this.footer)
        this.setState({ height: height < 625 ? 625 : height + 200 });
    }else{
      this.setState({ height:  windowHeight });
    }

  }

  selectedPlan = (planName) => {
    console.log(planName)
    this.setState({planName})
    this.authForm.switchToSignUp()
  }
  toggleLoading = () => {
    const { loading } = this.state
    this.setState({loading: !loading})
  }

  render() {

    const { height, planName, loading, email } = this.state

    return (

     <div style={{marginBottom: 0}}>

      <Row style={{height: height}}>
       <Col m={8} style={{height: height}}>
            <Row>
            <Col m={1} />
            <Col m={2}>
         
            <img src={logo} style={{width:125, marginTop: 45}} alt="logo" />
          
            </Col>
            <Col m={9}>
              <Section>
              <h2 className="left-align">Smarter Video Management</h2>
              <h5 className="left-align">Business & Sales Training </h5>
              </Section>
              <Section>
              <h4 style={{fontWeight: 'bold'}} className="left-align">Video Manager will help you: </h4>
                <div style={{marginLeft: 10, marginBottom:20 }} >
                <h5> - Group & Assign Training Videos</h5>
                <h5> - Ensure Video is Watched & Progress Tracked</h5>
                <h5> - Permission Your Management Hierarchy</h5>
                <h5> - Upload Videos Quickly (Vimeo & More!)</h5>
                </div>
              </Section>
            </Col>
            </Row>
            <Row m={12}>


            <Col m={3}>
            <Card style={{height: 300, borderWidth: planName === 'free' ? 5 : 0, borderColor: 'orange', borderStyle: 'solid'}}> 
            
            
            <Row s={12}>
                <Col s={12} className="center">
                <Section>
                <h5> Agent </h5>
                <h4> Free </h4>
                <p><small> part of a network </small></p>
                </Section>
                <Divider />
                <Section style={{marginTop: 20}}>
                {planName === 'free' ? <p style={{fontWeight: 'bold'}}>Register As Agent</p> : <Button className={'orange'} onClick={() => this.selectedPlan('free')}> Register </Button>}
                </Section>
                </Col>
              </Row>
            </Card>
            </Col>
       
            <Col m={3}>
            <Card style={{height: 300, borderWidth: planName === 'trial' ? 5 : 0, borderColor: '#45A29E', borderStyle: 'solid'}}> 
            
            
            <Row s={12}>
                <Col s={12} className="center">
                <Section>
                <h5> Trial</h5>
                <h4> Free </h4>
                <p><small> only one user </small></p>
                </Section>
                <Divider />
                <Section style={{marginTop: 20}}>
                {planName === 'trial' ? <p style={{fontWeight: 'bold'}}>Selected Plan</p> : <Button onClick={() => this.selectedPlan('trial')}> Free Trial </Button>}
                </Section>
                </Col>
              </Row>
            </Card>
            </Col>
             
              <Col m={3}>
              <Card style={{height: 300, borderWidth: planName === 'small' ? 5 : 0, borderColor: '#45A29E', borderStyle: 'solid'}}> 
              <Row s={12}>
                  <Col s={12} className="center">
                  <Section>
                  <h5> Small </h5>
                  <h4> $5.00 </h4>
                  <p><small> per user </small></p>
                  </Section>
                  <Divider />
                  <Section style={{marginTop: 20}}>
                  {planName === 'small' ? <p style={{fontWeight: 'bold'}}>Selected Plan</p> : <Button onClick={() => this.selectedPlan('small')}> Free Trial </Button>}
                  </Section>
                  </Col>
                </Row>
              </Card>
              </Col>
              
              <Col m={3}>
              <Card style={{height: 300, borderWidth: planName === 'large' ? 5 : 0, borderColor: '#45A29E', borderStyle: 'solid'}}> 
              <Row s={12}>
                  <Col s={12} className="center">
                  <Section>
                  <h5> Large </h5>
                  <h4> $3.00 </h4>
                  <p><small> per user </small></p>
                  </Section>
                  <Divider />
                  <Section style={{marginTop: 20}}>
                  {planName === 'large' ? <p style={{fontWeight: 'bold'}}>Selected Plan</p> : <Button onClick={() => this.selectedPlan('large')}> Free Trial </Button>}
                  </Section>
                  </Col>
                </Row>
              </Card>
              </Col>
           
            </Row>
        </Col> 

        <Col m={4} style={{height: height + 40, backgroundColor: '#45A29E'}}  >
            <Section className="center-align" style={{padding: 20, marginTop: 100}}>
                <AppWithAuth toggleLoading={this.toggleLoading} planName={planName} email={email ? email : null} ref={(ref) => {this.authForm = ref}}/>
            </Section>
           
        </Col>
      </Row>
   
      
        {/*<Footer ref={(ref) => {this.footer = ref}} />*/}

      </div>
      

    );
  }
}


export default withRouter(FrontPage);