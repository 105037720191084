
import React, {Component} from "react";

import {Footer} from 'react-materialize'

class MainFooter extends Component {
  componentDidMount() {
        const height = this.divElement.clientHeight;
        this.setState({ height });
      }
  makeFeature(){
        return  <div class="tap-target" data-target="menu">
        <div class="tap-target-content">
          <h5>Title</h5>
          <p>A bunch of text</p>
        </div>
      </div>
       }
     
  render() {
    //if (this.props.authState == "signedIn") {
      return (
        <div 
        ref={ (divElement) => this.divElement = divElement}
      >
            <Footer copyrights="2019 Web Development Systems"
            /*moreLinks={
            <a className="grey-text text-lighten-4 right" href="#!">More Links</a>
            }*/
            /*links={
            <ul>
                <li><a className="grey-text text-lighten-3" href="#!">Link 1</a></li>
                <li><a className="grey-text text-lighten-3" href="#!">Link 2</a></li>
                <li><a className="grey-text text-lighten-3" href="#!">Link 3</a></li>
                <li><a className="grey-text text-lighten-3" href="#!">Link 4</a></li>
            </ul>
            }*/
            className='teal'
            >
            <h5 className="white-text">The Video Manager</h5>
            <p className="grey-text text-lighten-4">The video manager is an business and sales training tool.</p>
            </Footer>
            </div>
      );
   /* } else {
      return null;
    }*/
  }
}

export default MainFooter;

