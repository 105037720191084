import React, { Component } from 'react';
import {Input, Row, Col, Button} from 'react-materialize'
import moment from 'moment'
import './App.css';

class Type extends Component {
  constructor(props) {
    super(props);
    this.state = {
        required: false,
        name: '', 
        description: '',
        isNew: true,
        doNotClear: false
    };
  }
  async componentDidMount(){
    const { type } = this.props
    if(type){
      this.setState({type, name: type, isNew: false})
    }
  }
  static getDerivedStateFromProps(props, state) {

    const { type } = props

    if(type){
        if (type !== state.type) {
            return { type, name: type, isNew: false, doNotClear: false }
        }else if(state.type === ''){
            return { type, name: type, isNew: false}
        }else if (type === state.type){
            return state
        }
    }

    if(state.doNotClear){
      return state
    }

    return {name: '', isNew: true, type: null, doNotClear: true}
  }
  
  refreshForm = () => {
    this.setState({
        type: '', 
        description: '',
        isNew: true,
    })
  }
  handleSubmit = () => {
    const { name, isNew } = this.state
    const { handleTypeUpdate } = this.props
    if(isNew){
      this.setState({doNotClear: false})
    }
    handleTypeUpdate(isNew, name)
  }

  handleInputUpdate = (field, value) => {
    this.setState({[field]: value })
  }
  render() {
    const { editable } = this.props
    const { name, description, isNew } = this.state

    console.log(editable)

    return (
      <Row style={{color: 'black'}}>
          <Col s={2} /> 
          <Col s={8} >      
          <Row>
            <h5>
            { isNew ? 'Add New Group' : editable  ? 'Edit Group' : '' }
            </h5>
          </Row>  
          <Row>
              <Input s={12} value={name} disabled={!editable} onChange={(event) => this.handleInputUpdate('name', event.target.value)} label={isNew ? "Group Name" : null }/>
          </Row>
             <Row>
                  {editable ? <Button s={12} waves='light' style={{marginTop: 20, width: '100%'}} onClick={this.handleSubmit}> {isNew ? 'Submit' : 'Save'} </Button> : null }
            </Row>
          </Col>
          <Col s={2} > </Col>  
      </Row>
    );
  }
}

export default Type;
