import React from "react";
import { SignIn } from "aws-amplify-react";
import { Card, Input, Row, Col, Button, Section, Divider} from 'react-materialize'
import LiveButton from './LiveButton';

export class CustomSignIn extends SignIn {
  constructor(props) {
    super(props);
    this._validAuthStates = ["signIn", "signedOut"];
  }
  switchToSignUp(){
    console.log('switchToSignUp')

    super.changeState("signUp")
  }
  showComponent(theme) {
    return (
      <Card>
      <h4 className="left-align">Sign in to your account</h4>
      <Input s={12}  
          id="username"
          key="username"
          name="username"
          type="email" 
          onChange={this.handleInputChange} 
          label="Email"/>
      <Input s={12}  
      id="password"
      key="password"
      name="password"
      type="password" 
      onChange={this.handleInputChange} 
      label="Password"/>
      <LiveButton 
      title={'Submit'}
      onClick={() => super.signIn()}
      //success={success}
     // error={error}
     // loading={loading}
      />
      <p className="left-align">
      Forgot your password?{" "}
      <a
        className="text-indigo cursor-pointer hover:text-indigo-darker"
        onClick={() => super.changeState("forgotPassword")}
      >
        Reset Password
      </a>
    </p>
    <p className="left-align">
    No Account*?{" "}
    <a
      className="text-indigo cursor-pointer hover:text-indigo-darker"
      onClick={() => super.changeState("signUp")}
    >
      Create account
    </a>
  </p>
  <p className="left-align"><small>*Team Leaders Issue Agent User Logins</small></p>


       </Card>
    );
  }
}


