
import React, {Component} from "react";
import {Navbar, NavItem, Row, Col } from 'react-materialize'
import logo from './vm_icon.png';

class MainHeader extends Component {


  render() {
      const { admin, setSelectedView, currentUser } = this.props
      const { email, isManager } = currentUser
      let info
      if(admin){
        info = <span style={{fontWeight:'bold'}}>{'(Admin)'}</span>
      }else if(isManager){
        info = <span style={{fontWeight:'bold'}}>{'(Manager)'}</span>
      }
      

      return (
        
        <Navbar brand=<Row s={12}><Col className="center-align"> <p style={{color:'#1F2833', fontSize: 14, fontWeight:'bold'}}>{`${email ? email : '' } `}{info}</p></Col><Col className="center-align"><img style={{width: 60, padding: 10}} src={logo}/></Col></Row> left>
        {admin ? <NavItem href='#' onClick={() => setSelectedView('Intro')} >Home</NavItem> : null}
        <NavItem href='#' onClick={() => setSelectedView('My Videos', 'white')} > My Videos</NavItem>
        {isManager || admin ? <NavItem href='#' onClick={() => setSelectedView('My Agents', 'white')} >My Agents</NavItem> : null}
        {admin ? <NavItem href='#' onClick={() => setSelectedView('Manage Videos', 'white')} >Manage Videos</NavItem> : null}
        {admin ? <NavItem href='#' onClick={() => setSelectedView('Account', 'white')}  >Account</NavItem> : null}
        <NavItem href='#' onClick={() => setSelectedView('Log Out')} >Log Out</NavItem>
       {/* <NavItem href='#' onClick={() => setSelectedView('Test')} >Test</NavItem>*/}
      </Navbar>
      )
  }
}

export default MainHeader;


