
import React from "react";
import { Auth } from 'aws-amplify';
import { SignUp } from "aws-amplify-react";
import SonicCustomSignUp from './SonicCustomSignUp';

export class CustomSignUp extends SignUp {
  constructor(props) {
    super(props);
    this.state = {
      loading: false
    }
    this._validAuthStates = ['signUp', "signedOut"];
    this.signUpFields = ['given_name', 'family_name', 'phone_number', 'email']

  }
  changeState = (screen) => {
    super.changeState(screen)
  }
  signUp = (payload) => {
    const { planName } = this.props
    const { username, password, email,  phone_number, given_name, family_name, emailValid } = payload
    if(!emailValid){
      return 
    }
    //need to validate is a good phone number and return teh format from the validator
    const formattedPhone = `+1${phone_number}`
    this.setState({loading: true})
    Auth.signUp({
      username,
      password,
      attributes: {
          email,
          phone_number: formattedPhone,
          given_name,
          family_name,
          'custom:planName': planName ? planName : 'trial'
      }}).then((data) => {
        console.log(data)
        this.setState({loading: false}, () => {
          super.changeState('confirmSignUp', data.user.username)
        })
    })
    .catch(err => this.error(err));
  }
  showComponent(theme) {
    const { loading } = this.state
    return <SonicCustomSignUp {...this.props} loading={loading} signUp={this.signUp} changeState={this.changeState}/>
  }
  

}


