import React, { Component } from 'react'
import { Container, Card, Row, Col, Button } from 'react-materialize'
import { CardElement,
    CardNumberElement,
    CardExpiryElement,
    CardCVCElement,
    PostalCodeElement,
    PaymentRequestButtonElement,
    IbanElement,
    IdealBankElement,
    StripeProvider,
    Elements,
    injectStripe, } from 'react-stripe-elements'

const handleBlur = () => {
  console.log('[blur]');
};
const handleChange = (change) => {
  console.log('[change]', change);
};
const handleClick = () => {
  console.log('[click]');
};
const handleFocus = () => {
  console.log('[focus]');
};
const handleReady = () => {
  console.log('[ready]');
};

const createOptions = (fontSize, padding) => {
  return {
    style: {
      base: {
        fontSize,
        color: '#424770',
        letterSpacing: '0.025em',
        fontFamily: 'Source Code Pro, monospace',
        '::placeholder': {
          color: '#aab7c4',
        },
        padding,
      },
      invalid: {
        color: '#9e2146',
      },
    },
  };
};

class _SplitForm extends React.Component {
  handleSubmit = (ev) => {
    const { addCard } = this.props
    ev.preventDefault();
    if (this.props.stripe) {
      this.props.stripe
        .createToken()
        .then((payload) => {
          console.log('[_CardForm]', payload)
          addCard(payload.token)
        })
    } else {
      console.log("Stripe.js hasn't loaded yet.");
    }
  };
  render() {
    const { newCard } = this.props
    return (
      <form onSubmit={this.handleSubmit}>
        <label>
          Card number
          <CardNumberElement
            onBlur={handleBlur}
            onChange={handleChange}
            onFocus={handleFocus}
            onReady={handleReady}
            {...createOptions(this.props.fontSize)}
          />
        </label>
        <label>
          Expiration date
          <CardExpiryElement
            onBlur={handleBlur}
            onChange={handleChange}
            onFocus={handleFocus}
            onReady={handleReady}
            {...createOptions(this.props.fontSize)}
          />
        </label>
        <label>
          CVC
          <CardCVCElement
            onBlur={handleBlur}
            onChange={handleChange}
            onFocus={handleFocus}
            onReady={handleReady}
            {...createOptions(this.props.fontSize)}
          />
        </label>
        <label>
          Postal code
          <PostalCodeElement
            onBlur={handleBlur}
            onChange={handleChange}
            onFocus={handleFocus}
            onReady={handleReady}
            {...createOptions(this.props.fontSize)}
          />
        </label>
        <Button>{newCard ? 'Add Card' : 'Update Card'}</Button>
      </form>
    );
  }
}

const SplitForm = injectStripe(_SplitForm);

class Checkout extends React.Component {
    constructor() {
      super();
      this.state = {
        elementFontSize: window.innerWidth < 450 ? '18px' : '24px',
      };
      window.addEventListener('resize', () => {
        if (window.innerWidth < 450 && this.state.elementFontSize !== '14px') {
          this.setState({elementFontSize: '14px'});
        } else if (
          window.innerWidth >= 450 &&
          this.state.elementFontSize !== '18px'
        ) {
          this.setState({elementFontSize: '18px'});
        }
      });
    }
  
    render() {

      return (
        <div>

          <Elements>
            <SplitForm {...this.props} fontSize={16} />
          </Elements>
         
        </div>
      );
    }
  }


  export default class Payment extends Component {
    constructor(props) {
        super(props);
        this.state = {
            stripeDoneLoading: false,
        };
      }
    async componentDidMount() {
  
        // componentDidMount only runs in a browser environment.
        // In addition to loading asynchronously, this code is safe to server-side render.
        // You can inject a script tag manually like this,
        // or you can use the 'async' attribute on the Stripe.js v3 <script> tag.
        const stripeJs = document.createElement('script');
        stripeJs.src = 'https://js.stripe.com/v3/';
        stripeJs.async = true;
        stripeJs.onload = () => {
          // The setTimeout lets us pretend that Stripe.js took a long time to load
          // Take it out of your production code!
          setTimeout(() => {
            this.setState({
              stripeDoneLoading: true,
              stripe: window.Stripe(process.env.REACT_APP_STRIPE)
            });
          }, 500);
        };
        document.body && document.body.appendChild(stripeJs);
      }
    render(){
        const { stripeDoneLoading } = this.state
        if(stripeDoneLoading){
            return (
                <Card style={{backgroundColor: '#1F2833', maxWidth: 550}}>
                <StripeProvider apiKey={process.env.REACT_APP_STRIPE}>
                  <Checkout {...this.props}/>
                </StripeProvider>
                </Card>
            );
        }
        return null

    }
};
