import React, { Component, Fragment } from 'react';
import Amplify, { Storage} from 'aws-amplify';
import { HashRouter, Switch, Route } from "react-router-dom"
import aws_exports from './aws-exports';
import FrontPage from './FrontPage'
import FrontPageUser from './FrontPageUser'
import FrontPageNewUser from './FrontPageNewUser'
import MainWithAuth from './MainWithAuth'

Amplify.configure(aws_exports);
Storage.configure({ level: 'private' });

export default class Front extends Component {
  state = {
    height: 0,
    expand: false
  };
  componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener('resize', this.updateWindowDimensions);
  }
  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions);
  }
  updateWindowDimensions = () => {
    // document.removeEventListener('keydown', this.onKeydown);

    const windowHeight = window.innerHeight;

    if(this.footer){
        console.log(this.footer.divElement.offsetTop)
        const footerHeight = this.footer.divElement.clientHeight
     
        const height = windowHeight - (footerHeight ? footerHeight : 0)
        //const height = footerTopOffset ? footerTopOffset : windowHeight
        console.log(this.footer)
        this.setState({ height: height < 625 ? 625 : height });
    }else{
      this.setState({ height:  windowHeight });
    }

  }
  
  expandWindow = (expand) => {
    console.log(expand)
    this.setState({expand})
  }
  render() {

    return (
      <HashRouter>
      <Switch>
        <Route exact path="/" component={FrontPage} />
        <Route exact path="/users" component={FrontPageUser} />
        <Route exact path="/newusers" component={FrontPageNewUser} />
        <Route exact path="/home" component={MainWithAuth} />
      </Switch>
      </HashRouter>
    );
  
   
  }
}
