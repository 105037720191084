// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_content_delivery_bucket": "sonicviewer-20190105105225--hostingbucket",
    "aws_content_delivery_bucket_region": "us-east-1",
    "aws_content_delivery_url": "https://d8lo30398wu4a.cloudfront.net",
    "aws_cognito_identity_pool_id": "us-east-1:e98dfb1c-51d6-4c31-bee4-ac25e2fe47f0",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_ZkjMbcdXr",
    "aws_user_pools_web_client_id": "65k46gfetsg8c6esguhgvmfm0s",
    "aws_mobile_analytics_app_id": "a74355a38ceb4e6daa18bbc7cd7a24b1",
    "aws_mobile_analytics_app_region": "us-east-1",
    "aws_user_files_s3_bucket": "sonicviewerd58b52f1778f4ef98f56fbf3a694295a",
    "aws_user_files_s3_bucket_region": "us-east-1",
    "aws_dynamodb_all_tables_region": "us-east-1",
    "aws_dynamodb_table_schemas": [
        {
            "tableName": "agent_videos",
            "region": "us-east-1"
        },
        {
            "tableName": "agent_hierarchy",
            "region": "us-east-1"
        },
        {
            "tableName": "viewer_users",
            "region": "us-east-1"
        },
        {
            "tableName": "company_agents",
            "region": "us-east-1"
        },
        {
            "tableName": "user_accounts",
            "region": "us-east-1"
        }
    ],
    "aws_cloud_logic_custom": [
        {
            "name": "apicef032be",
            "endpoint": "https://h0koxlyxv4.execute-api.us-east-1.amazonaws.com/Prod",
            "region": "us-east-1"
        }
    ]
};


export default awsmobile;
