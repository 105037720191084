import React, { Fragment } from "react";
import { ForgotPassword } from "aws-amplify-react";
import { Card, Input, Row, Col, Button, Section, Divider} from 'react-materialize'
import LiveButton from './LiveButton';

export class CustomForgotPassword extends ForgotPassword {
  constructor(props) {
    super(props);
    this._validAuthStates = ["forgotPassword"];
  }
  makeSendView = () => {
    return  (
        <Fragment>
        <Input s={12}  
        id="username"
        key="username"
        name="username"
        type="email" 
        onChange={this.handleInputChange} 
        label="Email"/>          
          <LiveButton 
          title={'Submit'}
          onClick={() => super.send()}
          //success={success}
         // error={error}
         // loading={loading}
        />
        </Fragment>)

  }
  makeSubmitView = () => {
      return  (
      <Input s={12}  
      id="code"
      key="code"
      name="code"
      onChange={this.handleInputChange} 
      label="Code"/> )
  }
  showComponent(theme) {
    const { authData={} } = this.props;
    return (
      <Card>
      <h5>Forgot password</h5>
      { this.state.delivery || authData.username ? this.submitView() : this.sendView() }
      { this.state.delivery || authData.username ? 
        <LiveButton title={'Submit'} onClick={() => super.submit()}/>:
        <LiveButton title={'Send Code'} onClick={() => super.send()}/>
    }

      { this.state.delivery || authData.username ?
        <a onClick={() => super.send()}>{'Resend Code'}</a> :
        <a onClick={() => this.changeState('signIn')}>{'  Back to sign In'}
        </a>
    }       
       </Card>
    );
  }
}


