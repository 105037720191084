import React, { Component, Fragment } from 'react';
import { Auth } from 'aws-amplify';
import API from './API'
import {Card, Breadcrumb, MenuItem, Row, Col, Dropdown, NavItem, CollectionItem, Input, Pagination, Icon, Button, Badge, Divider} from 'react-materialize'
import moment from 'moment'
import Users from './Users'
import User from './User'
import logo from './logo.png';
import './App.css';

function onlyUnique(value, index, self) { 
  return self.indexOf(value) === index;
}


class Manager extends Component {
  constructor(props) {
    super(props);

    this.state = {
      videos: [],
      agentCount: 0,
    //  hierarchyList:[],
      users: [],
      total: 0, 
      active: 0,
      inactive: 0,
      pending: 0,
      agents: [],
      manager: {},
      agent: {},
      type: 'All',
      selectedManagerSubList: [],
      selectedAgent: {
        firstName: '',
        lastName: '',
        email:''
      }
    };

  }
  async componentDidMount(){
    const { currentUser } = this.props
    if(currentUser){
      currentUser.agentID = currentUser.userID
      //this.listAgents(currentUser)
      this.handleHierarchySelection(currentUser)

    }
    //  this.listHierarchy()
  }
 
  /*listHierarchy = async (id) => {
    const { userID } = this.props.currentUser
    if(id){
      const hierarchyList = await API.get(process.env.REACT_APP_API , `/hierarchy/mymanager/${id}`, userID);
     // this.setState({[id]: hierarchyList})
      this.setState({hierarchyList})
    }else{
      const hierarchyList = await API.get(process.env.REACT_APP_API , `/hierarchy`, userID);
      this.setState({hierarchyList})
    }
  }*/
  clearSubManager = () => {
    const { currentUser } = this.props
    this.setState({selectedManagerSubList: []})
   // this.listHierarchy()
    this.listAgents(currentUser)
  }
  addSubManager = async (agent) => {
    const { userID } = agent
    const { selectedManagerSubList } = this.state

    if(selectedManagerSubList.includes(agent)){
      return
    }
    agent.agentID = userID
    selectedManagerSubList.push(agent)
    this.handleHierarchySelection(agent)
  }
  removeSubManager = agent => {

    const { selectedManagerSubList } = this.state
    let subList = [...selectedManagerSubList]
    const indexOfAgent = subList.indexOf(agent)
    subList = subList.slice(0, indexOfAgent + 1)
    this.setState({ selectedManagerSubList: subList})
    if(subList.length > 0){
      const lastAgent = subList[subList.length - 1]
      this.handleHierarchySelection(lastAgent)
    }else{
      const { currentUser } = this.props
      this.handleHierarchySelection(currentUser)
    }
  }
  listAgents = async (manager) => {
    const { userID } = this.props.currentUser
    const { agentID, email } = manager
    this.setState({ manager})
    const agents = await API.get(process.env.REACT_APP_API , `/users/manager/${agentID}`, userID);
    let rightColumn = 'newUser'
    if(agents.length > 0){
      this.selectAgent( agents[0])
      rightColumn = 'videos'
    }

    this.setState({agents, agentCount: agents.length,  rightColumn, selectedAgent: {
      firstName: '',
      lastName: '',
      email:''
    }})

    this.listAllUsers(email)

  }
  
  listAllUsers = async (email) => {
    const { userID } = this.props.currentUser
    const response = await API.get(process.env.REACT_APP_API , `/users/all/manager/${email}`, userID);
    console.log(response)
    if('success' in response){
      const { data: users } = response

    const active = users.filter((item) => {
          return item.status === 'CONFIRMED'
    })

    const inactive = users.filter((item) => {
      return item.status === 'Disabled / CONFIRMED'
    })

    const pending = users.filter((item) => {
      return item.status === 'FORCE_CHANGE_PASSWORD'
    })

    const all = users.map((item) => {
      if('status' in item){
        return item
      }
      return {status: 'NOT LOGGED IN', ...item}
    })

    this.setState({users: all, userCount: users.length, active: active.length, inactive :inactive.length, pending: pending.length})
    
    }else{
      const { message } = response
      return this.setState({error: message, loading: false })
    }
  }
  selectAgent =  async (agent) => {
    const { userID, isManager } = agent
    this.setState({agent})
    const videos = await API.get(process.env.REACT_APP_API , `/videos/objects/${userID}`, userID);
    this.setState({videos, selectedAgent: agent, rightColumn: 'videos'})
  }
  handleTypeSelection = (type, event) => {
    this.setState({type})
  }
  handleHierarchySelection = (user, event) => {
    const { userID } = user
    user.agentID = userID
    this.listAgents(user)
  }

  makeVideoTypeDropdown = () => {
    const { videoGroups } = this.props
    const { type } = this.state;

    const navOptions = videoGroups.map(videoType => {
          return <NavItem key={videoType} href='#' onClick={() => this.handleTypeSelection(videoType)} >{videoType}</NavItem>
    })

    navOptions.unshift(<NavItem href='#' onClick={() => this.handleTypeSelection('All')} key={'All'} >{'All'}</NavItem>)

    return (<Dropdown
    trigger={
      <Button>{type}<Icon right>arrow_drop_down</Icon></Button>
    }>
   {navOptions}
  </Dropdown>)
  }
/*
  makeHierarchyDropdown = (listID) => {
  
    const { hierarchyList, manager: selectedManager } = this.state



    let list = hierarchyList

    if(listID){
      list = this.state[listID]
    }


    let currentUserIndex
    const navOptions = list.map((manager, index) => {
          const { firstName, lastName, agentID, userID } = manager
          if(agentID === userID){
            currentUserIndex = index
          }
          return <NavItem key={userID} href='#' onClick={() => this.handleHierarchySelection(manager)} key={agentID} >{`${firstName} ${lastName}`}</NavItem>
    })


    if(currentUserIndex){
        const currentUserItem = navOptions[currentUserIndex]
        navOptions.splice(currentUserIndex, 1);
        navOptions.unshift(currentUserItem)
    }



    const { firstName, lastName } = selectedManager

    return (<Dropdown
    trigger={
      <Button>{`${firstName} ${lastName}`}<Icon right>arrow_drop_down</Icon></Button>
    }>
   {navOptions}
  </Dropdown>)

  }*/

  makeSubManagerList = () => {
    console.log(this.props)
    const { currentUser } = this.props
    const { selectedManagerSubList } = this.state
    const { firstName: first, lastName: last } = currentUser

 
    if (selectedManagerSubList.length < 1){
     // return <p>{`${first} ${last}`}</p>
    }

    const names = selectedManagerSubList.map((manager) => {

        const { firstName, lastName, userID } = manager
        return <MenuItem key={userID} onClick={() => {this.removeSubManager(manager)}}> {`${firstName} ${lastName}`}</MenuItem>
        //const { userID } = manager
       // return this.makeHierarchyDropdown(userID)
    })

    names.unshift(<MenuItem key={'currentUser'} onClick={() => {this.clearSubManager()}}> {`${first} ${last}`}</MenuItem>)

    return <Row><Breadcrumb >
      {names}
    </Breadcrumb></Row>

  }
  makeAgentMenu = () => {

    const { agent, agents } = this.state
    const { userID: selectedID } = agent

    return agents.map((item, index) => {
      const { userID, firstName, lastName, isManager } = item

      return (<CollectionItem 
      key={`Agent${userID}`}
      href={`#/home`} 
      active={selectedID === userID} 
      onClick={() => this.selectAgent(item)} 
      > 
      {`${firstName} ${lastName}`}


      {isManager ? <Badge 
      style={{color: 'white'}} 
      className={`green`}
      >
      {'Manager' } 
      </Badge> : null }

      </CollectionItem>
      
      )

    })
  }

  makeVideoMenu = (type) => {

    if(!type){
      return null
    }
    //use type to filter list
    const { videos } = this.state;

    let filteredVideos = [...videos]

    if(type !== 'All'){
      filteredVideos = filteredVideos.filter((video) => {
        return video.type === type
      })
    }

    return filteredVideos.map((video, index) => {

      const { videoID, percent, name, type} = video
    
     return (<CollectionItem 
      key={videoID}
      href={`#/home`} 
      //onClick={() => this.selectVideo(index, videoID)} use to send reminder alert
      > 
      {name}
      <Badge 
      style={{color: 'white'}} 
      className={`${percent === 0 ? 'blue' : percent < 1 ? 'red' : 'green'}`}
      >
      {percent > 0 ? `${Math.round(percent * 100)}%` : 'Not Started' } </Badge>
      </CollectionItem>)
    })
  }
  makeLeftColumn = () => {
    const { currentUser } = this.props
    const { admin } = currentUser 
    const { rightColumn, agentCount } = this.state
        if(rightColumn === 'newUser'){
          if(agentCount > 0) {
            return <Card ><h6>You are currently adding a new user. This will result in an additonal user fee being charged to your companies account.</h6></Card>
          }else{

            if(admin){
              return <Card ><h6>There are currently no users assigned to you. Your manager can assign users to you from their account. If you add a new user, this will result in an additonal user fee being charged to your companies account.</h6></Card>
            }else{
              return (<Card >
                  <h6>Adding a new user will result in an additonal user fee being charged to your networks account.</h6>
                  </Card>)
            }
          }
        }else{
         return <div className="collection">{this.makeAgentMenu()}</div>
        }
  }
  makeRightColumn = () => {
    const { rightColumn, type, selectedAgent, manager, user } = this.state
    const { currentUser, videoGroups } = this.props
    const { firstName, lastName, email, userID, isManager } = selectedAgent

    

    if(rightColumn === 'editUser'){
      return <User user={selectedAgent} refreshAgents={this.listAgents} manager={manager} currentUser={currentUser} groups={videoGroups}/>
    }else if(rightColumn === 'newUser'){
      return <User user={null} manager={manager} refreshAgents={this.listAgents} currentUser={currentUser} groups={videoGroups}/>
    }

    return  (<div>
                <h5> Videos </h5>
                <Row>
                  <Col s={6}>{this.makeVideoTypeDropdown()}</Col>
                  <Col s={2} />
                  <Col s={4}> 
                      <h5> {`${firstName} ${lastName}`} </h5> 
                      <h6> {`${email}`}</h6>  
                      {isManager ?  
                      <Button style={{backgroundColor: 'white', color: 'blue'}} href="#" onClick={() => {this.addSubManager(selectedAgent)}}> {`> View Agents`}</Button> 
                      : null }
                  </Col>
                 


                </Row>
                <div className="collection">
                    {this.makeVideoMenu(type)}
                </div> 
      </div>)
    }

  render() {
    const { rightColumn, agentCount, users, total, active, inactive, pending } = this.state
    return (
      <Row style={{marginLeft: 20, marginTop: 20}}>
          <h6 style={{fontWeight: 'bold'}}>MANAGERS:</h6>
          <Row s={12}>
            <Col s={11}>{this.makeSubManagerList()}</Col>
          </Row>
      <Row>

      <Row>
    <Col s={5}>

        <Row>
        <Divider />
        <Col s={6}>

        <h6 style={{fontWeight: 'bold'}}> {`${agentCount} AGENTS`} </h6>
        </Col>

        {agentCount > 0 ? rightColumn === 'newUser' || rightColumn === 'editUser' ?  <Col s={6}><Button className="Button red" onClick={() => this.setState({rightColumn: 'videos'})}> Close Editor </Button>  </Col>:  <Col s={3}><Button className="Button red" onClick={() => this.setState({rightColumn: 'editUser'})}> Edit </Button>  </Col> : null} 
        <Col s={3}> {rightColumn === 'newUser' || rightColumn === 'editUser' ? null : <Button onClick={() => this.setState({rightColumn: 'newUser'})}> Add </Button> } </Col>
      
        </Row>
        {this.makeLeftColumn()}
        </Col>
        <Col s={7}>
            {this.makeRightColumn()}
        </Col>
        </Row>
        </Row>

        <Row>
          <Col m={1} />
          <Col m={10} >
            <h6 style={{color: '#1F2833', fontWeight: 'bold'}}>{`Total: ${total} Active: ${active}  Inactive: ${inactive}  Pending: ${pending}`} </h6>
            <Card style={{marginBottom: 200}}>
              <Users data={users}/>
            </Card>
          </Col>
          <Col m={1} />
        </Row>
          
 
      </Row>
    );
  }
  
}

export default Manager;

