import React, { Component, Fragment } from 'react';
import { Storage } from 'aws-amplify';
import {Card, Divider, Row, Col, Dropdown, NavItem, CollectionItem, Input, Pagination, Icon, Button, Badge, Modal} from 'react-materialize'
import moment from 'moment'
import List from './List'
import Video from './Video'
import Type from './Type'
import logo from './logo.png';
import './App.css';


function replaceAt(array, index, value) {
  const ret = array.slice(0);
  ret[index] = value;
  return ret;
}

class Admin extends Component {
  constructor(props) {
    super(props);

    this.state = {
      type: null,
      selectedVideo: {},
      videos: [],
      videoGroups: []
    };

  }
  async componentDidMount(){
    const { videos, videoGroups } = this.props
    console.log(this.props)
    if(videoGroups.length > 0){
      const videoGroups = Object.keys(videos)
      this.setState({...videos, videoGroups, type: videoGroups[0], rightColumn: 'staticType' })
    }else{
      this.setState({rightColumn: 'newType' })
    } 
  }
  save = async () => {
    const { currentUser, refreshVideos } = this.props
    console.log(this.state)
    const { type, selectedVideo, videoGroups, rightColumn, videos, ...videoCollections } = this.state
    console.log(videoCollections)
    const { network } = currentUser
    
    Storage.put(`videos/collections/${network}/videoCollection.json`, JSON.stringify(videoCollections))
    .then(() => {
      refreshVideos(network)
    })
    .catch(err => console.log(err));

  }
  selectVideo =  async (video) => {
    this.setState({ selectedVideo: video, rightColumn: 'editVideo'})
  }
  deleteType = (type) => {
   // this.modal.modal('open')
  }
  handleTypeSelection = (type, event) => {
    this.setState({type})
  }
  makeVideoTypeDropdown = () => {
 
    const { type, videoGroups } = this.state;

    if(!videoGroups){
        return (<Dropdown
            trigger={
              <Button>{type}<Icon right>arrow_drop_down</Icon></Button>
            }>
           {[]}
          </Dropdown>)
    }


    const navOptions = videoGroups.map(videoType => {
          return <NavItem href='#' onClick={() => this.handleTypeSelection(videoType)} key={videoType} >{videoType}</NavItem>
    })

    return (<Dropdown
    trigger={
      <Button>{type}<Icon right>arrow_drop_down</Icon></Button>
    }>
   {navOptions}
  </Dropdown>)
  }

 

  makeVideoMenu = () => {
    const { type } = this.state
    if(!type){
      return null
    }else if(!(type in this.state)){
        return null
    }

    let filteredVideos = [...this.state[type]]

    return filteredVideos.map((video, index) => {

     const { videoID, name, required } = video
    
     return (<CollectionItem 
      key={videoID}
      href={`#/home`} 
      onClick={() => this.selectVideo(video)} 
      > 
      {name}
      </CollectionItem>)
    })
  }
  handleTypeUpdate = (isNew, updatedType) => {
    console.log('handleTypeUpdate')
    const { type, videoGroups } = this.state

    if(!isNew){
      const {[type]:existingType } = this.state
      const currentIndex = videoGroups.indexOf(type)
      const newVideoTypes = replaceAt(videoGroups, currentIndex, updatedType);
      const payload = {}
      payload[updatedType] = [...existingType]
      payload[type] = null
      payload.videoGroups = newVideoTypes
      payload.type = updatedType
      console.log(payload)
        this.setState(payload, () => {
          this.save()
        })
    }else{
        const newVideoTypes = [...videoGroups, updatedType]
        console.log({[updatedType]:[], videoGroups: newVideoTypes, type: updatedType})

        this.setState({[updatedType]:[], videoGroups: newVideoTypes, type: updatedType}, () => {
          const { goNext } = this.props
          this.save()
          if(goNext){
            goNext()
          }
        })
    }
 
  }
  handleVideoUpdate = (updatedVideo) => {
    const { type, [type]:videoCollection } = this.state
    let found = false
    const { videoID : updatedID} = updatedVideo
    const updatedCollection = videoCollection.map(video => {
          const { videoID } = video
          if(updatedID === videoID) {
            found = true
            return updatedVideo
          }
          return video
    })

    if(found){
      this.setState({[type]:updatedCollection}, () => {
        this.save()
      })
    }else{
      this.setState({[type]:[...videoCollection, updatedVideo]}, () => {
        this.save()
      })
    }

  }
  makeRightColumn = () => {
    const { selectedVideo, rightColumn, type } = this.state

    console.log(rightColumn)

    if(rightColumn === 'editVideo'){
      return <Video video={selectedVideo} handleVideoUpdate={this.handleVideoUpdate}/>
    }else if(rightColumn === 'newVideo'){
      return <Video video={null} handleVideoUpdate={this.handleVideoUpdate}/>
    }else if (rightColumn === 'editType') {
      return <Type editable={true}  type={type} handleTypeUpdate={this.handleTypeUpdate}/>
    }else if  (rightColumn === 'newType') {
      return <Type editable={true}  type={null} handleTypeUpdate={this.handleTypeUpdate}/>
    }else if (rightColumn === 'staticType'){
      return <Type editable={false} type={type}/>
    }

   return null
  }
  makeTypeOptions = () => {
    const { short } = this.props
    const { rightColumn, videoGroups } = this.state
    const hasGroup = videoGroups.length > 0
    if (!hasGroup && short){
      return null
    } 
    if(rightColumn === 'newType' || rightColumn === 'editType'){
      return <Row><Col s={6}> <Button className="Button red" onClick={() => this.setState({rightColumn: 'staticType'})}> Close Editor </Button></Col></Row>
    }else{
      return (<Fragment>
        <Col s={6}>  <Button onClick={() => this.setState({rightColumn: 'newType'})}>   New Group  </Button></Col>
        <Col s={6}>  <Button onClick={() => this.setState({rightColumn: 'editType'})}> Edit Group  </Button></Col>
      </Fragment>)
  }
  }
  makeEditOptions = () => {
    const { short } = this.props
    const { rightColumn, videoGroups } = this.state

    const hasGroup = videoGroups.length > 0
    if (!hasGroup && short){
      return null
    } 
    if(rightColumn === 'newVideo' || rightColumn === 'editVideo'){
        return <Row><Col s={12}> <Button className="Button red" onClick={() => this.setState({rightColumn: 'staticType'})}> Close Editor </Button></Col></Row>
    }else{
        return (<Row>
          <Col s={12}> <Button onClick={() => this.setState({rightColumn: 'newVideo'})}> Add New Video  </Button></Col> 
        </Row>)
    }

  }
  handleVideoOrderUpdate = (newOrder) => {
    const { type } = this.state
    this.setState({[type] : newOrder})

  }
  makeLeftColumn = () => {
    return <Fragment><Row><h5>Videos</h5></Row>
    <div className="collection">{this.makeVideoMenu()}</div></Fragment>
  }
  makeInstructionsoLeftColumn = () => {
     return( <Fragment><h3>Welcome to Adding Your First Video</h3>

      <h5>Step 1 - Create a New Group</h5>
      <p>Each video must be assigned to a group. These groups allow you organize your videos. Groups also allow you to control which videos certain groups of your users can view. </p>
      <p>For example, lets say you have a mangement team and a sales team. You may want to create one group for etc.....</p>
   
      <h5>Step 2 - Etc..</h5></Fragment>)
  }
  render() {
    const { short } = this.props
    const { type, videoGroups } = this.state

    const hasGroup = videoGroups.length > 0

    let filteredVideos = []
    if(type){
      filteredVideos = [...this.state[type]]
    }

    filteredVideos = filteredVideos.filter((value) => {
      return value ? true : false
    }).map((item, index) => {
      item.position = index
      return item
      })

    return (
      <Fragment>
      <Row style={{marginLeft: 20, marginTop: 20}}>
      {hasGroup && !short ? <h6 style={{fontWeight: 'bold'}}>VIDEO GROUP: </h6> : null }
          
        <Row s={12}>
            <Col s={5}>   
              {hasGroup && !short ? this.makeVideoTypeDropdown()  : null }
            </Col>
            <Col s={2}>   
                {this.makeEditOptions()}
            </Col>
            <Col s={5}>   
                 {this.makeTypeOptions()}
            </Col>
        </Row>

            <Col s={5}>
              <Divider />
              {hasGroup && !short && !short ? this.makeLeftColumn() : this.makeInstructionsoLeftColumn() }

            </Col>
            <Col s={1} />
            <Col s={5} >
            <Card style={{backgroundColor: '#1F2833'}}>
              <Card style={{backgroundColor: 'white', padding: 20}}> {this.makeRightColumn()}  </Card>
              </Card>
            </Col>
            <Col s={1} />
          </Row>
      <Modal 
        ref={modal => {this.modal = modal}}
        header='Warning!'
      >
         <p>{`You are about to delete ${type}, all associated videos will be lost. This cannot be undone`}</p>
         <Button>Ok Delete</Button>
         <Button>Cancel</Button>
      </Modal>
</Fragment>
    );
  }
  
}

export default Admin;

