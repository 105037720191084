import React, { Component } from "react";
import { makeData } from "./Utils";
import ReactTable from "react-table";
import { Input, Row, Col, Button, Section, Divider} from 'react-materialize'
import "react-table/react-table.css";

export default class Users extends Component {
  constructor() {
    super();
    this.state = {
     // data: makeData()
    };
  }
  render() {
    const { data } = this.props;
    return (
      <div>
        <ReactTable
          data={data}
          columns={[
            {
              Header: "First Name",
              accessor: "firstName"
            },
            {
              Header: "Last Name",
              accessor: "lastName"
            },
            {
              Header: "Email",
              accessor: "email"
            },
            {
              Header: "Status",
              accessor: "status"
            }
          ]}
          defaultSorted={[
            {
              id: "status",
              desc: true
            }
          ]}
          defaultPageSize={10}
         
        />
      </div>
    );
  }
}


