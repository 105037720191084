import React, { Component } from 'react';
import API from './API'
import {Row, Col, Input, Section, Icon, Button, Card, Container, Divider} from 'react-materialize'
import './App.css';
import Admin from './Admin'
import NewUsers from './NewUsers'
import Account from './Account'
import ViewSlide from './ViewSlide'
import LiveButton from './LiveButton'

export default class Intro extends Component {
  constructor(props) {
    super(props);
    this.state = {
        loaded: false,
        selectedStep: 0,
        completedSteps:[],
        steps:[{title: 'Step 1',
                name: 'REATE NETWORK',
                description: 'First start by creating a new network for your account. This cannot be changed.'},
                {title: 'Step 2',
                name: 'ADD VIDEOS',
                description: 'Add videos yourself by selecting Manage Videos from the top navigation menu..'},
                {title: 'Step 3',
                name: 'PICK PLAN',
                description: 'Pick a plan by yourself by selecting Account from the top navigation menu.'},
                {title: 'Step 4',
                name: 'ADD USERS',
                description: 'Add a user yourself by selecting Account from the top navigation menu.'},
            ]
        };
  }
  async componentDidMount(){
     const { videoGroups, currentUser } = this.props
     const { userID } = currentUser
     const { completedSteps } = this.state
     const hasNetwork = this.isValidNetwork()
     let newSlide = 0
     
     if(hasNetwork){
        const currentSteps = [...completedSteps, 0]
        if(videoGroups.length >0){
            currentSteps.push(1)

            const response = await API.get(process.env.REACT_APP_API , `/acount`, userID);
            if('success' in response){
              const { data } = response
              if(data){
              const { card } = data
              if(card){
                currentSteps.push(2)
              }
            }
            }
        }
        this.setState({completedSteps: currentSteps, loaded: true}, () => {
           this.changeSelectedView(newSlide + currentSteps[currentSteps.length - 1] + 1)
        })
     }
     this.getAccount()
     this.setState({loaded: true})
  }

  getAccount = async () => {
    const { completedSteps } = this.state
    const { userID } = this.props.currentUser
    const response = await API.get(process.env.REACT_APP_API , `/acount`, userID);
    console.log(response)
    if('success' in response){
      const { data } = response
      if(data){
        const { card } = data
        if(card){
             const currentSteps = [...completedSteps, 2]
             this.setState({completedSteps: currentSteps})
        }
      }
    }else{
      const { message } = response
      this.setState({error: message, loading: false })
    }
  }
  isValidNetwork = () => {
     const { currentUser } = this.props
     const { network } = currentUser
        let hasNetwork = false
    
        if(network ){
            if(network !== 'none'){
                hasNetwork = true
            }
        }

        return hasNetwork
   }
   addToCompleted = (step) => {
    const { completedSteps } = this.state
    const currentSteps = [...completedSteps, step]
    this.setState({completedSteps: currentSteps})
   }
   completeSlide = (step) => {
    console.log('completeSlide')
    console.log(step)
    this.addToCompleted(step)
    setTimeout(() => {this.changeSelectedView(step + 1)}, 1000);
    
  }
  changeSelectedView = (selectedStep) => {
    const { completedSteps, selectedStep: currentStep } = this.state

    if(completedSteps.length === 0){
        return
    }
    const lastCompleted = completedSteps[completedSteps.length - 1]

    console.log(selectedStep - lastCompleted)

    if((selectedStep - lastCompleted) > 1){
        return
    }

    if(!completedSteps.includes(selectedStep)){
        this.carousel.setActiveSlide(selectedStep)
        this.setState({selectedStep}, () => {
          //  this.addToCompleted(selectedStep)
           //need to have a listner to caraoul and when completed then add to completed so need a pendingComplete in the state
        })
    }

   /* if(hasNetwork){
       if(selectedStep === 3){
                this.carousel.setActiveSlide(selectedStep)
                this.setState({selectedStep})
        }else{
            this.carousel.setActiveSlide(selectedStep)
            this.setState({selectedStep})
        }
     }*/
  }
  createNetwork = async () => {
    const { currentUser } = this.props
    const { network } = this.state
    const { userID } = currentUser
    const body = {assignDomain : false}
    this.setState({loading: true})
    console.log(API)
    const response = await API.put(process.env.REACT_APP_API , `/acount/network/${network}`, {body}, userID);
    console.log(response)
    if('success' in response){
        currentUser.network = network
        this.setState({loading: false, success: 'Succesfully Created Network!', currentUser}, () =>{
            this.completeSlide(0);
        })
    }else if('error' in response){
      const { error } = response
      this.setState({error:error.message, loading: false})
    }
  }
  handleInputUpdate = (field, value) => {
    const newValue = value.replace(/[^\w.-]+/g, "")
    this.setState({[field] :newValue })
  }
  makeStepCell = (step, instruction) => {
    const { title, name, description } = instruction
    const { selectedStep, completedSteps } = this.state
    const hasNetwork = this.isValidNetwork()
    const completed = completedSteps.includes(step)
    const selectedStyle = {minHeight: 200, minWidth: 200, margin: 50, borderColor: '#1F2833', borderStyle: 'solid'}
    const unSelectedStyle = {minHeight: 200, minWidth: 200, margin: 50, backgroundColor: completed ? 'gray' : 'white'}
    const selectedClassName = 'hoverable z-depth-5'
    const unSelectedClassName = 'hoverable'
    
    return <Col m={3} key={step}>
    <Card className={selectedStep === step ? selectedClassName : unSelectedClassName} style={ selectedStep === 0 ? selectedStyle : unSelectedStyle} onClick={(event) => this.changeSelectedView(step)}>    
        <h5>{title}</h5>
        <p> {name}</p>
        {hasNetwork && !completed ? <a> Click to Get Started</a> : 'Completed'}
        <Divider />
        <p style={{marginTop: 10}}><small>{description}</small></p>
    </Card>
     </Col>

  }
  makeSteps = () => {
    const { steps } = this.state
    return steps.map((instx, index) => {
        console.log(index)
        return this.makeStepCell(index, instx)
    })
  }


  render() {
  
    const { currentUser } = this.props
    const { selectedStep, success, error, loading, completedSteps, loaded } = this.state
    const { network } = currentUser
    let isPaidAccount = false
    const hasNetwork = this.isValidNetwork()

    const selectedStyle = {minHeight: 200, minWidth: 200, margin: 50, borderColor: '#1F2833', borderStyle: 'solid'}
    const unSelectedStyle = {minHeight: 200, minWidth: 200, margin: 50}
    const selectedClassName = 'hoverable z-depth-5'
    const unSelectedClassName = 'hoverable'

    console.log(completedSteps)

    return (
        <Row style={{marginBottom:100}}>
        <Row className="valign-wrapper" style={{backgroundColor:  '#45A29E'}}>
            
         {this.makeSteps()}
       </Row>
          {/*  < {...this.props}  /> */}
        <Row>
        {loaded ?
        <ViewSlide
   
        ref={(ref) => {this.carousel = ref}}
        components={[
            (
        <Card style={{backgroundColor: '#1F2833', color: 'white', marginLeft: 20}}>
            <Section style={{marginLeft: 20}}>
            <h2> Welcome To The Video Manager</h2>
            <h4>First lets name your network. </h4>
            <h5>A network name must not contain spaces, you companies domain would make good network name.</h5>
            <p style={{marginTop: 10}}>You may use underscores or periods instead of spaces. Some examples without spaces are mycompanyname, my_company_name or my.company.name  </p>
                <Row>  
                <Col m={6}>
                    <Card style={{marginTop: 20, color: 'black'}}>
                        <Input 
                        disabled={hasNetwork}
                        m={12} onChange={(event) => this.handleInputUpdate('network', event.target.value)} 
                        label={hasNetwork ? network : "Network Name"}/>
                        <LiveButton 
                        disabled={hasNetwork}
                        title={'Submit'} 
                        onClick={this.createNetwork}
                        success={success}
                        error={error}
                        loading={loading}
                        />
                        </Card>
                </Col>  
                </Row>  
                <Row m={6}/>  
            </Section>
        </Card>),
            <Card>
           <Admin {...this.props} short={true} goNext={() => this.completeSlide(1)}/>
            </Card>, 
            <Card>
             <Account {...this.props} short={true} goNext={() => this.completeSlide(2)}/>
            </Card>,
            <Card>
             <NewUsers {...this.props} />
            </Card>
        ]}
        />: null}
       </Row>
    </Row>
    );
  }
  
}
//completedSteps.includes() to not render class not needed wait till can find a way to know when animation is finished