import React, { Component, Fragment } from 'react';
import API from './API'
import { Modal, Section, Card, Icon, Input, Divider, Row, Col, Button } from 'react-materialize'
import moment from 'moment'
import Users from './Users'
import './App.css';
import Payment from './Payment'

class Account extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showConfirmation: false,
      loading: false,
      userCount: 0,
      users: [],
      active: 0,
      inactive: 0,
      pending:0,
      total: 0,
      estimatedAgents: 0,
      price: 0,
      charges:[
        {date: moment().subtract(1, 'months').format(), amount: '9823'},
        {date: moment().subtract(2, 'months').format(), amount: '10023'},
        {date: moment().subtract(3, 'months').format(), amount: '9723'},
      ],
   
    };
  }
  async componentDidMount(){
   const { currentUser } = this.props
   const { planName } = currentUser

    this.getCustomerCard()
    this.getCustomerCharges()
    this.listAllAgents()
    this.getAccount()
    this.setState({planName})
  
  }
  handleAgentEstimate = (event) => {
    console.log(event)
    const { value } = event.target
    console.log(value)
    const estimatedAgents = parseInt(value)
   
    this.setState({estimatedAgents})
  }
  changeAccount = (name) => {
      //need to ask for confirmatio
      console.log(name)
      this.setState({selectedPlanName: name, showConfirmation: true }, () => {
        this.confirmAccountChange()
      })
  }
  getAccount = async () => {
 
    const { userID } = this.props.currentUser
    const response = await API.get(process.env.REACT_APP_API  ? process.env.REACT_APP_API  : 'apicef032be', `/acount`, userID);
    console.log(response)
    if('success' in response){
      const { data } = response
      if(data){
        const { price } = data
        this.setState({price})
      }
    }else{
      const { message } = response
      this.setState({error: message, loading: false })
    }
  }
  hideConfirmation = () => {
    const { loading } = this.state
    if(!loading){
      this.setState({showConfirmation: false})
    }
  }
  
  addCard = async (payload) => {
    console.log(payload)
    const { selectedPlanName } = this.state
    console.log(selectedPlanName)
    if(selectedPlanName === 'undefined' || selectedPlanName === null || selectedPlanName === 'trial' || selectedPlanName === 'free'){
      //need an error message to choose paid plan
      return
    }
    const { goNext, currentUser } = this.props
    const { userID } = currentUser
    const body = {...payload}
    this.setState({loading: true})
    const response = await API.post(process.env.REACT_APP_API , `/acount/customer`, {body}, userID);
    console.log(response)
    if('success' in response){
      const { data } = response
      const { data: cards } = data.sources
      const card = cards[0]
      this.setState({card: card}, () => {
        if(goNext){
          goNext()
        }
      })
    }else{
      const { message } = response
      this.setState({error: message, loading: false })
    }
  }
  confirmAccountChange = async () => {

    const { selectedPlanName, planName } = this.state
    const currentPlan = planName
    this.setState({loading: true, planName: selectedPlanName })
    const { userID } = this.props.currentUser

    const response = await API.post(process.env.REACT_APP_API , `/acount/registar/${selectedPlanName}`, {}, userID);
    console.log(response)
    if('success' in response){
      this.setState({showConfirmation: false, loading: false, planName: selectedPlanName })
    }else{
      const { message } = response
      this.setState({error: message, loading: false, planName: currentPlan })
    }
  }
  getCustomerCharges = async () => {
    const { userID } = this.props.currentUser
    
    const response = await API.get(process.env.REACT_APP_API , `/acount/customer/charges`, userID);
    console.log(response)

    if('success' in response){
      const {data: charges } = response.data
      this.setState({charges})
    }else{
      const { message } = response
      this.setState({error: message, loading: false })
    }
  }
  getCustomerCard = async () => {
    const { userID } = this.props.currentUser
    
    const response = await API.get(process.env.REACT_APP_API , `/acount/customer/customer`, userID);
    console.log(response)
    if('success' in response){
      const { data } = response
      const { sources } = data
      if(sources){
        const { data: cards } = sources
        const card = cards[0]
        this.setState({card: card})
      }

    }else{
      const { message } = response
      this.setState({error: message, loading: false })
    }
  }
  listAllAgents = async () => {
    const { userID } = this.props.currentUser
    const response = await API.get(process.env.REACT_APP_API , `/users/all/fuck`, userID);
    console.log(response)
    if('success' in response){
      const { data: users } = response

    const active = users.filter((item) => {
          return item.status === 'CONFIRMED'
    })

    const inactive = users.filter((item) => {
      return item.status === 'Disabled / CONFIRMED'
    })

    const pending = users.filter((item) => {
      return item.status === 'FORCE_CHANGE_PASSWORD'
    })

    const all = users.map((item) => {
      if('status' in item){
        return item
      }
      return {status: 'NOT LOGGED IN', ...item}
    })

    this.setState({users: all, userCount: users.length, active: active.length, inactive :inactive.length, pending: pending.length})
    
    }else{
      const { message } = response
      return this.setState({error: message, loading: false })
    }
  }

  makeBillHistory = () => {
      const { charges } = this.state
      const dates = charges.map((item) => {
          const { date } = item
          return <h6 key={date} style={{paddingBottom: 5}}>{`${moment(date).format('MM/DD/YYYY')}`}</h6>
      })
      const amounts = charges.map((item) => {
        const { amount } = item
        return <h6 key={amount} style={{paddingBottom: 5}}>{`$${amount / 100}`}</h6>
    })
      return (
        <Fragment>
          <Col s={6} className='left'>
                {dates}
          </Col>
          <Col s={6} className='left'>
              {amounts}
          </Col>
        </Fragment>
      )

  }
  makeCard = () => {

    const { card } = this.state
    if(card){
      const { brand, exp_year, last4} = card
     return <Card  style={{ maxWidth: 550, borderWidth: 5, borderColor: '#1F2833', borderStyle: 'solid'}}>
      <Row s={12}>
       <Col s={2} className="left">
          <img style={{height: 40, maxWidth: 64}} src={`https://s3.amazonaws.com/files.sonicwebdev.com/images/cards/${brand.trim(' ', '')}.png`} alt={brand} />
       </Col>
       <Col s={10} className="center">
          <h5>{`.... .... .... ${last4} exp: ${exp_year}`}</h5>
       </Col>
     </Row>
     </Card> 
    }
    return null

  }
  render() {
    const { active, pending, inactive, total, estimatedAgents, price, planName, card, users } = this.state
    const { currentUser, short } = this.props
    const { firstName, lastName, email, network } = currentUser
    const chargeDate = moment().add(1, 'months').startOf('month').format('MM/DD/YYYY')

    return (
  
    <Row style={{paddingTop: 20}}>
   
    <Row>
        <Col m={8}>
        <Row m={12}>
       
        <Col m={4}>
            <Card style={{height: 300, borderWidth: planName === 'trial' ? 5 : 0, borderColor: '#45A29E', borderStyle: 'solid'}}> 
                <Row s={12}>
                    <Col s={12} className="center">
                    <Section>
                    <h5> Trial </h5>
                    <h4> Free </h4>
                    <p><small> only one user </small></p>
                    </Section>
                    <Divider />
                    <Section style={{marginTop: 20}}>
                    {planName === 'trial' ? <p style={{fontWeight: 'bold'}}>Your Current Plan</p> : <Button onClick={() => this.changeAccount('trial')}> Select </Button>}
                    </Section>
                    </Col>
                    <Row className="center">
                    <p><small> {`1 user`}</small></p>
                    </Row>
                </Row>
              </Card>
        </Col>
         
          <Col m={4}>
          <Card style={{height: 300, borderWidth: planName === 'small' ? 5 : 0, borderColor: '#45A29E', borderStyle: 'solid'}}> 
          <Row s={12}>
              <Col s={12} className="center">
              <Section>
              <h5> Small Office </h5>
             
              <h4> $5</h4>
              <p><small> per user </small></p>
              </Section>
              <Divider />
        
              <Section style={{marginTop: 20}}>
              {planName === 'small' ? <p style={{fontWeight: 'bold'}}>Your Current Plan</p> : <Button onClick={() => this.changeAccount('small')}> Select </Button>}
              </Section>
              </Col>
              <Row className="center">
              <p><small> {`< 100 users`}</small></p>
              </Row>
            </Row>
          </Card>
          </Col>
          
          <Col m={4}>
          <Card style={{height: 300, borderWidth: planName === 'large' ? 5 : 0, borderColor: '#45A29E', borderStyle: 'solid'}}> 
          <Row s={12}>
              <Col s={12} className="center">
              <Section>
              <h5> Large Office </h5>
              <h4> $3 </h4>
              <p><small> per user </small></p>
              </Section>
              <Divider />
              <Section style={{marginTop: 20}}>
              {planName === 'large' ? <p style={{fontWeight: 'bold'}}>Your Current Plan</p> : <Button onClick={() => this.changeAccount('large')}> Select </Button>}
              </Section>
              </Col>
              <Row className="center">
              <p><small> {`> 100 users`}</small></p>
              </Row>
            </Row>
          </Card>
          </Col>
       
        </Row>

        <Row m={12}>
          <Modal
         //header='Modal Header'
          bottomSheet
          trigger={<Button style={{marginLeft: 20, marginTop: 20}}>Bill Calculator</Button>}>
    
          <Card style={{height: 300}}>
            <Row m={12}>  
            <Col m={8}>
                <h5> Monthly charges are based on number of active users. You can use the below calculator to estimate your montlhy bill. </h5>
                <h6> Charges are incurred pro rata for the remainder of the month for each user added. To avoid multiple charges a day when adding new users, your card will be charged the following day for the prior days new users.</h6>
                <a><p><small>Please read our terms/conditions and our refund policies for additional information </small></p></a>
                <Input s={6} onChange={this.handleAgentEstimate} label="Users" validate><Icon>account_circle</Icon></Input>
              </Col>
              <Col s={4} className="left">
                  <h5> Bill Estimate </h5>
                  <Divider />
                  <h5 style={{marginTop: 10}}>{`${estimatedAgents} Agents`}</h5>
                  <h5 style={{fontWeight: 'bold'}}>{`USD $${(price * estimatedAgents) / 100}`}</h5>
                  <h6 >{`Next Charge Date: ${moment(chargeDate).format('MM/DD/YYYY')}`}</h6> 
              </Col>
              </Row>
           </Card>
     </Modal></Row>

        {card && !short ? <Row m={12}>
             <Col m={8}>
             <Card style={{height: 275}}>
             <Row s={12}>
                 <Col s={6} className="left">
                      <h4> Billing Contacts </h4>
                      <h5> {`${firstName} ${lastName}`}</h5>
                      <h6> Send copies of invoices to </h6>
                      <h6> {email} </h6>
                 </Col>
                 <Col s={6} className="left">
                    <Row><h5> Bill history </h5></Row>
                    <Divider />
                    <Row style={{marginTop: 10}}> {this.makeBillHistory()}</Row>
                    <Row style={{marginTop: 20}}> <a href="#">View full history</a></Row>
                 </Col>
          
              </Row>
     


             </Card>
             </Col>

             <Col m={4}>
             <Card style={{height: 275}}>
             <Row s={12}>
                 <Col s={12} className="left">
                    <h5> Bill estimate </h5>
                    <Divider />
                    <h5 style={{marginTop: 10}}>{`${active} Agents`}</h5>
                    <h5 style={{fontWeight: 'bold'}}>{`USD $${(active * price) / 100}`}</h5>
                    <h6 >{`Next Charge Date: ${moment(chargeDate).format('MM/DD/YYYY')}`}</h6> 
                    <Row style={{marginTop: 20}}> <a>View full estimate</a></Row>
                 </Col>
          
              </Row>
            
             </Card>
             </Col>
          
             </Row> 
             : null }
        </Col>
        <Col m={4}>
           {this.makeCard()}
           <Payment addCard={this.addCard} newCard={card ? false : true}/>
        </Col>
    </Row>


    <Row>
    <Divider />
      <Section style={{marginLeft: 40}}>
       <h2> {`Users assigned to ${network} network`}</h2>
       <h5> - Users can be deactivated or deleted from the user profile on your manager tab</h5>
       <h5> - Only active users are included in  the monthly billing cycle.</h5>
       </Section>
    </Row> 

   <Row>
   <Col m={1} />
   <Col m={10} >
     <h6 style={{color: '#1F2833', fontWeight: 'bold'}}>{`Total: ${total} Active: ${active}  Inactive: ${inactive}  Pending: ${pending}`} </h6>
    <Card style={{marginBottom: 200}}>
       <Users data={users}/>
    </Card>
   </Col>
   <Col m={1} />
   </Row>
    </Row>
 
    );
  }
  
}

export default Account;