import React, { Component } from 'react';
import { Auth } from 'aws-amplify';
import {Dropdown, NavItem, CollectionItem, Input, Row, Col, Pagination, Icon, Button, Badge} from 'react-materialize'
import moment from 'moment'

import logo from './logo.png';
import './App.css';


class Video extends Component {
  constructor(props) {
    super(props);
    this.state = {
        required: false,
        name: '', 
        videoID: '',
        description: '',
        isNew: true,
    };
  }
  async componentDidMount(){

    const { video } = this.props

    if(video){
      this.setState({...video, isNew: false})
    }
  }
  static getDerivedStateFromProps(props, state) {
    // Any time the current user changes,
    // Reset any parts of state that are tied to that user.
    // In this simple example, that's just the email.
    const { video } = props
    
    if(video){
        if (video.videoID !== state.videoID) {
            return { ...video }
        }else if(state.videoID === ''){
            return { ...video }
        }
    }

    return state

  }
  refreshForm = () => {
    this.setState({
        required: false,
        name: '', 
        videoID: '',
        description: '',
        isNew: true,
    })
  }
  handleSubmit = () => {
    const { isNew } = this.state
    const { handleVideoUpdate } = this.props
    handleVideoUpdate({...this.state})
    if(isNew){
      this.refreshForm()
    }
  }

  handleInputUpdate = (field, value) => {


    let { required } = this.state
 
    if (field === 'required'){
      this.setState({required: !required})
      return
    }

    this.setState({[field]: value })
  }
  render() {

    const { required, name, videoID, description, isNew } = this.state

//need to select type
    return (
      <Row style={{color: 'black'}}>
          <Col s={1} > </Col>
          <Col s={10} >      
          <Row>
            <h5>
            { isNew ? 'Add New Video' : 'Edit Video'}
            </h5>
          </Row>  
          <Row>
              <Input s={12} value={name} onChange={(event) => this.handleInputUpdate('name', event.target.value)} label={isNew ? "Video Name" : null }/>
              <Input s={12} value={videoID} onChange={(event) => this.handleInputUpdate('videoID', event.target.value)} label={isNew ? "Vimeo Video Id" : null }/>
              <Input name='group1' type='checkbox' checked={required} value='required' label='Is Required' onChange={(event) => this.handleInputUpdate('required', event.target.value)} />
          </Row>
             <Row>
              <Col s={3} />
                  <Col s={6} >
                  <Button waves='light' style={{marginTop: 20, width: '100%'}} onClick={this.handleSubmit}> {isNew ? 'Submit' : 'Save'} </Button> 
                  </Col>
              <Col s={3} />
            </Row>
          </Col>
          <Col s={1} > </Col>  
      </Row>
    );
  }
}

export default Video;
