import React, { Component, Fragment } from 'react';
import { Card, Input, Row, Col, Button, Section, Divider} from 'react-materialize'
import validator from 'validator'
import LiveButton from './LiveButton';
import './App.css';

class SonicCustomSignup extends Component {
  constructor(props) {
    super(props);
    this.state = {
        emailValid: true,
        lockEmail: false
    };
  }
  componentDidMount(){
    const { email } = this.props
    console.log(email)
    if(email){
      this.setState({username: email, email: email, lockEmail: true})
    }
  }
  handleInputChangeHere = (field, value) => {

    if(field === 'phone_line_number'){
      const phone_number = value.replace(/[^0-9]/, '')
      if(phone_number.length > 10){
        return
      }
      console.log(phone_number)
      this.setState({phone_number}) 
    }else if(field === 'username'){
        let emailValid = false
        if(validator.isEmail(value)){
            emailValid = true
        }

     this.setState({[field]: value, email: value, emailValid })
    }else{
      this.setState({[field]: value})
    }
  }
  render() {
    const { planName, changeState, signUp, loading } = this.props
    const { given_name, family_name, phone_number, username, password, emailValid, lockEmail } = this.state

    return (
      <Card>
      {planName === 'free' ? 
      <Fragment><h4 className="left-align">Agent sign up</h4>
      <h5 className="left-align">Sign up as part of an existing network! </h5>

      </Fragment>
      :
      <Fragment><h4 className="left-align">Create your account</h4>
      <h5 className="left-align">Get started and add your employees today!</h5></Fragment>}



      <Input s={12}  
      id="given_name"
      key="given_name"
      name="given_name"
      value={given_name ? given_name : ''}
      onChange={(event) => this.handleInputChangeHere('given_name', event.target.value )} 
      label="First Name"/>

      <Input s={12}  
      id="family_name"
      key="family_name"
      name="family_name"
      value={family_name ? family_name : ''}
      onChange={(event) => this.handleInputChangeHere('family_name', event.target.value )} 
      label="Last Name"/>

      <Input s={12}  
      id="phone_line_number"
      key="phone_line_number"
      name="phone_line_number"
      value={phone_number ? phone_number : ''}
      type="tel"
      onChange={(event) => this.handleInputChangeHere('phone_line_number', event.target.value )} 
      label="Phone"/>

      <Input s={12}  
      id="username"
      key="username"
      name="username"
      type="email" 
      disabled={lockEmail}
      error={!emailValid ? ' ' : null}
      value={username ? username : ''}
      onChange={(event) => this.handleInputChangeHere('username', event.target.value )} 
      label={username ? null : 'Email'}
      />

      <Input s={12}  
      id="password"
      key="password"
      name="password"
      type="password" 
      value={password ? password : ''}
      onChange={(event) => this.handleInputChangeHere('password', event.target.value )} 
      label="Password"/>

      <LiveButton 
      title={'Submit'}
      onClick={() => signUp({...this.state})}
      //success={success}
     // error={error}
      loading={loading}
      />
      <p className="left-align">
      Forgot your password?{" "}
      <a
        className="text-indigo cursor-pointer hover:text-indigo-darker"
        onClick={() => changeState("forgotPassword")}
      >
        Reset Password
      </a>
    </p>
    <p className="left-align">
    Have Account?{" "}
    <a
      className="text-indigo cursor-pointer hover:text-indigo-darker"
      onClick={() => changeState("signIn")}
    >
      Sign In
    </a>
  </p>
  <p className="left-align"><small>*Networks must add agent user logins to see videos</small></p>

       </Card>
    );
    }
}

export default SonicCustomSignup;