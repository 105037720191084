import React, { Component } from 'react';
import {Row, Col, Section, Card, Button, Divider} from 'react-materialize'
import { withRouter} from "react-router-dom"
import qs from 'qs'
import AppWithAuth from './AppWithAuth'
import Footer from './Footer'
import logo from './vm_icon.png';

class FrontPageUser extends Component {
  state = {
    height: 0,
    planName: 'free',
    newUser: true,
  };
  componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener('resize', this.updateWindowDimensions);
    console.log(this.props.history)
    const settings = qs.parse(this.props.history.location.search.substring(1));
    console.log(settings)
    const { planName, ...other } = settings 
    this.setState({...other}, () => {
     // this.selectedPlan(planName)
    })
  }
  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions);
  }
  updateWindowDimensions = () => {
    // document.removeEventListener('keydown', this.onKeydown);

    const windowHeight = window.innerHeight;

    if(this.footer){
        console.log(this.footer.divElement.offsetTop)
        const footerHeight = this.footer.divElement.clientHeight
     
        const height = windowHeight - (footerHeight ? footerHeight : 0)
        //const height = footerTopOffset ? footerTopOffset : windowHeight
        console.log(this.footer)
        this.setState({ height: height < 625 ? 625 : height + 200 });
    }else{
      this.setState({ height:  windowHeight });
    }

  }

  selectedPlan = (planName) => {
    console.log(planName)
    this.setState({planName})
  //  this.authForm.switchToSignUp()
  }
  toggleLoading = () => {
    const { loading } = this.state
    this.setState({loading: !loading})
  }
  clearNewSignUp = () => {
     this.setState({email: null}, () => {
        this.selectedPlan('free')
  })
    
  }
  render() {

    const { height, planName, email, newUser} = this.state

    return (

     <div style={{marginBottom: 0}}>

      <Row style={{height: height}}>
       <Col m={8} style={{height: height}}>
            <Row>
            <Col m={1} />
            <Col m={2}>
         
            <img src={logo} style={{width:125, marginTop: 45}} alt="logo" />
          
            </Col>
            <Col m={9}>
              <Section>
              <h2 className="left-align">Smarter Video Management</h2>
              <h5 className="left-align">Business & Sales Training </h5>
              </Section>
              <Section>
              <h4 style={{fontWeight: 'bold'}} className="left-align">Video Manager will help you: </h4>
                <div style={{marginLeft: 10, marginBottom:20 }} >
                <h5> - Group & Assign Training Videos</h5>
                <h5> - Ensure Video is Watched & Progress Tracked</h5>
                <h5> - Permission Your Management Hierarchy</h5>
                <h5> - Upload Videos Quickly (Vimeo & More!)</h5>
                </div>
              </Section>
            </Col>
            </Row>
            <Row m={12}>

            <Col m={4} />
           
            <Col m={4}>
            <Card style={{height: 300, borderWidth:  5, borderColor: 'teal', borderStyle: 'solid'}}> 
            
            
            <Row s={12}>
                <Col s={12} className="center">
                <Section>
                <h5> Agent/Manager </h5>
      
                <h4> Welcome!</h4>
                </Section>
                <Divider />
                <Section style={{marginTop: 20}}>
                    <h6> Please sign up</h6>
                </Section>
                </Col>
              </Row>
            </Card>
            </Col>
       
           
              
              <Col m={4} />
          
            </Row>
        </Col> 

        <Col m={4} style={{height: height + 40, backgroundColor: '#45A29E'}}  >
            <Section className="center-align" style={{padding: 20, marginTop: 100}}>
                <AppWithAuth newUser={newUser} toggleLoading={this.toggleLoading} planName={planName} email={email ? email : null} clearNewSignUp={this.clearNewSignUp} ref={(ref) => {this.authForm = ref}}/>
            </Section>
           
        </Col>
      </Row>
   
      
     {/*   <Footer ref={(ref) => {this.footer = ref}} /> */}

      </div>
      

    );
  }
}

export default withRouter(FrontPageUser);