import React, { Component, Fragment } from 'react';
import { Input, Row, Col, Button, Section, Divider} from 'react-materialize'
import { Storage } from 'aws-amplify';
import moment from 'moment'
import LiveButton from './LiveButton';
import './App.css';

class NewUsers extends Component {
  constructor(props) {
    super(props);
    this.state = {

    };
  }

  upload = (event) => {
    console.log(event.target.files[0])
    const file = event.target.files[0]
    const { network } = this.props.currentUser
    Storage.put(`users/${network}/network_${moment().format()}_users.csv`, file)
    .catch(err => console.log(err));
  }

  render() {

      return (
      
        <Row>
        <Section style={{marginLeft: 20}}>
        <h2>Now lets get your users onboard!</h2>
        <h5>Users can be uploaded using the <a>CSV Upload Template</a>.</h5>
        <h6>You can also choose to enter your users one at a time by clicking on My Agents in the navigation menu. </h6>

        <Section style={{marginTop: 20}}><Input type="file" label="File" s={12} onChange={this.upload}/> </Section>
        </Section>
      </Row>
      
      )

  }
}

export default NewUsers;

