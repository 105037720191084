
import React, { Component } from 'react';
import Amplify, { Storage} from 'aws-amplify';
import aws_exports from './aws-exports';
import Main from './Main'

Amplify.configure(aws_exports);
Storage.configure({ level: 'private' });

class App extends Component {


  render() {
    if (this.props.authState == "signedIn") {
      return (
          <Main />
      );
    } else {
      return null;
    }
  }
}

export default App;


/*

import React, { Component } from 'react';
import Amplify, { Storage} from 'aws-amplify';
import { withAuthenticator } from 'aws-amplify-react';
import aws_exports from './aws-exports';
import Main from './Main'
//import './App.css';

Amplify.configure(aws_exports);
Storage.configure({ level: 'private' });

class App extends Component {

  render() {
    return (
      <Main />
    );
  }
}

export default withAuthenticator(App, true);
*/
