
import React, { Fragment } from 'react';
import { Row, Col, Button, ProgressBar, Icon } from 'react-materialize'


const LiveButton = props => {
        const { title, onClick, success, error, loading, disabled } = props

        return (
            <Fragment>
                <Button disabled={loading || disabled} waves='light' style={{marginTop: 20, marginBottom: 10, width: '100%'}} onClick={onClick}> { loading ? 'Processing ...' : title} </Button>
                 {loading ?  <ProgressBar /> : null}
                 {success || error ? <Row className="valign-wrapper"> 
                                {/*  <Col s={2} />     
                                <Col s={1} className="valign-wrapper"> <Icon>check_circle_outline</Icon> </Col> */}
                                <Col s={12} className="center-align"><h6 style={{fontWeight:'bold', color: success ? '#26A69A' : 'red'}}>{`${success ? success : error}`}</h6></Col>
                </Row>: null}
            </Fragment>
        );
}

export default LiveButton;